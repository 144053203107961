import { StatusType, datadogLogs } from '@datadog/browser-logs';
import { RumInitConfiguration, datadogRum } from '@datadog/browser-rum';
import { datadogConfig } from '../datadog-config';

export interface IInitOptions {
  appId: string;
  clientToken: string;
  env: string;
  logLevel: StatusType;
  service: string;
  trackInteractions?: boolean;
  version: string;
  allowedTracingUrls?: RumInitConfiguration['allowedTracingUrls'];
}

export const initLogging = (enableHttp: boolean, options: IInitOptions): void => {
  const sharedConfig = {
    sampleRate: datadogConfig.sampleRate,
    site: datadogConfig.site,
    useSecureSessionCookie: datadogConfig.useSecureSessionCookie,
  };

  const envSanitized = options.env.toLowerCase(); // datadog enforces lowercase env

  datadogLogs.init({
    ...sharedConfig,

    // from options
    clientToken: options.clientToken,
    env: envSanitized,
    service: options.service,

    forwardConsoleLogs: [],
    forwardErrorsToLogs: true,
    forwardReports: [],
    telemetrySampleRate: 0,
  });

  datadogLogs.logger.setHandler(enableHttp ? ['console', 'http'] : 'console');
  datadogLogs.logger.setLevel(options.logLevel);

  if (enableHttp) {
    datadogRum.init({
      ...sharedConfig,

      // from options
      allowedTracingUrls: options.allowedTracingUrls,
      applicationId: options.appId,
      clientToken: options.clientToken,
      env: envSanitized,
      service: options.service,
      trackInteractions: options.trackInteractions || false,
      version: options.version,

      defaultPrivacyLevel: 'mask-user-input',
      sessionReplaySampleRate: 0,
      telemetrySampleRate: 0,

      /** @link https://docs.datadoghq.com/real_user_monitoring/feature_flag_tracking/ */
      enableExperimentalFeatures: ['feature_flags'],
    });

    datadogRum.startSessionReplayRecording();
  }
};

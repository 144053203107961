import React, { useCallback } from 'react';
import newsHeroGraphic from '../../assets/news-hero-graphic.png';
import { newsItems } from '../../data/news-items';
import { FeaturedNewsTile } from '../news-tile/news-tile';

export const NewsHero: React.FC = () => {
  const openArticleLink = useCallback(() => {
    window.open(newsItems.featuredNews.articleLink, '_blank');
  }, []);
  return (
    <div
      className="flex justify-center max-w-[1160px] min-h-[480px] relative z-10 mx-8 lg:mx-[5rem] xl:mx-[10rem] 2xl:mx-[14rem] py-10  xl:mb-24 cursor-pointer"
      onClick={openArticleLink}
    >
      <div className="flex flex-col-reverse md:flex-row gap-6 w-full md:h-[500px]  text-white border border-solid border-opacity-[0.5] border-white">
        <div className="flex md:w-2/5 p-6">
          <FeaturedNewsTile data={newsItems.featuredNews} />
        </div>
        <div className="flex md:w-3/5">
          <img className="md:w-full md:h-full" src={newsHeroGraphic} alt="news-hero-graphic" />
        </div>
      </div>
    </div>
  );
};

import { TNewsItems } from '../types';

// Golden source for news items to be displayed on the ETF Accelerator marketing site news page.
// For questions or assistance, please reach out to shadow-ui team (shadow-ui@internal.email.gs.com).

// Add your news item(s) below.
// featuredNews: the news item you want to spotlight on the News page hero at the top of the page
// otherNews: all other news items
export const newsItems: TNewsItems = {
  featuredNews: {
    date: '2024-04-01',
    mediaOutlet: 'CNBC',
    category: 'News Article',
    headline: 'Why Goldman Sachs is helping its clients launch ETFs',
    summaryCopy:
      'Steve Sachs, Goldman Sachs ETF Accelerator global COO, joined CNBC’s Bob Pisani to discuss the opportunities in active ETFs and how the ETF Accelerator platform works.',
    articleLink:
      'https://www.cnbc.com/2024/04/01/goldman-sachs-helps-its-clients-launch-etfs-.html',
  },
  otherNews: [
    {
      date: '2024-06-27',
      mediaOutlet: 'GoldmanSachs.com',
      category: 'Press Release',
      headline:
        'Goldman Sachs ETF Accelerator Announces First Fund Launched in EMEA Through the Platform',
      articleLink:
        'https://www.goldmansachs.com/media-relations/press-releases/2024/etf-accelerator-announces-first-fund-launched-in-emea.html',
    },
    {
      date: '2024-04-26',
      mediaOutlet: 'ETF.com',
      category: 'Podcast',
      headline: 'The Goldman Sachs ETF Accelerator Explained',
      articleLink: 'https://www.etf.com/sections/podcasts/goldman-sachs-etf-accelerator-explained',
    },
    {
      date: '2024-03-28',
      mediaOutlet: 'CNBC',
      category: 'Video',
      headline: 'How Goldman Sachs ETF Accelerator works',
      summaryCopy:
        'Steve Sachs, Goldman Sachs ETF Accelerator global COO, joined CNBC’s Bob Pisani to discuss the opportunities in active ETFs and how the ETF Accelerator platform works.',
      articleLink:
        'https://www.cnbc.com/video/2024/03/28/goldman-sachs-steve-sachs-on-how-goldman-sachs-etf-accelerator-works.html',
    },
    {
      date: '2024-03-26',
      mediaOutlet: 'GoldmanSachs.com',
      category: 'Press Release',
      headline:
        'Goldman Sachs ETF Accelerator Announces New Fund Launched Through the Platform with the Listing of Eagle Capital Management’s First ETF',
      articleLink:
        'https://www.goldmansachs.com/media-relations/press-releases/2024/gs-etf-accelerator-new-fund-launched.html',
    },
    {
      date: '2023-11-15',
      mediaOutlet: 'GoldmanSachs.com',
      category: 'Press Release',
      headline:
        'Goldman Sachs ETF Accelerator Announces Latest Fund Launched Through the Platform with the Listing of GMO’s First ETF',
      articleLink:
        'https://www.goldmansachs.com/media-relations/press-releases/2023/gs-etf-accelerator-gmo-launch.html',
    },
    {
      date: '2023-10-24',
      mediaOutlet: 'Tearsheet',
      category: 'News Article',
      headline:
        'Behind Goldman Sachs’ venture into the ETF launch business: 5 questions with Lisa Mantil, Global Head of Goldman Sachs ETF Accelerator',
      articleLink:
        'https://tearsheet.co/banking/why-goldman-sachs-ventured-into-the-etf-launch-business-5-questions-with-lisa-mantil-global-head-of-goldman-sachs-etf-accelerator/',
    },
    {
      date: '2023-10-23',
      mediaOutlet: 'Bloomberg News',
      category: 'News Article',
      headline: 'Goldman’s Create-Your-Own ETF Hub for Clients Debuts First Funds',
      articleLink:
        'https://www.bloomberg.com/news/articles/2023-10-05/goldman-s-create-your-own-etf-hub-for-clients-debuts-first-funds',
    },
    {
      date: '2023-10-09',
      mediaOutlet: 'MarketWatch',
      category: 'News Article',
      headline: 'Hedge funds, family offices among those turning to Goldman Sachs ETF Accelerator',
      articleLink:
        'https://www.marketwatch.com/story/hedge-funds-family-offices-among-those-turning-to-goldman-sachs-etf-accelerator-cdb167a2',
    },
    {
      date: '2023-10-05',
      mediaOutlet: 'Financial Times',
      category: 'News Article',
      headline: 'Goldman Sachs wades into third-party ETF business',
      articleLink: 'https://www.ft.com/content/0a1afeb8-15c6-4312-a49b-b2052c948851',
    },
    {
      date: '2023-10-05',
      mediaOutlet: 'GoldmanSachs.com',
      category: 'News Article',
      headline:
        'Goldman Sachs Announces the Launch of the First Funds Through Goldman Sachs ETF Accelerator',
      articleLink:
        'https://www.goldmansachs.com/media-relations/press-releases/2023/goldman-sachs-launch-of-first-funds-through-etf-accelerator.html',
    },
    {
      date: '2023-09-25',
      mediaOutlet: 'ETF Stream',
      category: 'News Article',
      headline:
        'The Big Interview: Goldman Sachs ETF Accelerator head reveals plans for European expansion',
      articleLink:
        'https://www.etfstream.com/articles/the-big-interview-goldman-sachs-etf-accelerator-head-reveals-plans-for-european-expansion',
    },
    {
      date: '2021-11-01',
      mediaOutlet: 'Wall Street Journal',
      category: 'News Article',
      headline: 'Goldman Sachs Enters Business of Advising ETF Launches',
      articleLink:
        'https://www.wsj.com/livecoverage/stock-market-news-today-11-01-2022/card/goldman-sachs-enters-business-of-advising-etf-launches-yHwZIrEkYgVa0pdf6gE3',
    },
  ],
};

import { Button } from '@gs-ux-uitoolkit-react/button';
import { Checkbox, CheckboxChangeEvent } from '@gs-ux-uitoolkit-react/checkbox';
import { Form, FormGroup, FormFeedback } from '@gs-ux-uitoolkit-react/form';
import { Input } from '@gs-ux-uitoolkit-react/input';
import { Label } from '@gs-ux-uitoolkit-react/label';
import { Radio } from '@gs-ux-uitoolkit-react/radio';
import {
  createStyleSheet,
  CssFactoryPropsType,
  StyleSheet,
  useStyleSheet,
} from '@gs-ux-uitoolkit-react/style';
import { ThemeProvider } from '@gs-ux-uitoolkit-react/theme';
import { shadowDarkTheme } from '@shadow/shadow-components/dark-theme';
import React, { useCallback } from 'react';
import { Controller, ControllerRenderProps, useForm, FormProvider } from 'react-hook-form';

export const GetInTouch: React.FC = () => {
  return (
    <div className={'flex justify-center items-center relative mt-56 lg:mx-20 xl:mx-56'}>
      <div
        id={'get-in-touch'}
        className="flex xl:h-[650px] lg:h-[700px] sm:h-[800px] h-[1200px] w-full relative z-10 justify-center items-center mx-7 p-[70px] "
        style={{
          borderRadius: '8px',
          background: 'linear-gradient(0deg, #0E171F 0%, #0E171F 100%), #0E171F',
          boxShadow:
            '0px 0px 250px 0px rgba(94, 108, 255, 0.20), 0px 0px 250px 0px rgba(94, 108, 255, 0.20)',
        }}
      >
        <div className="flex flex-col gap-10 justify-center items-center align-middle w-[90%]">
          <div className="text-white font-basisLight text-[40px] lg:text-5xl">Get in touch</div>
          <div className="w-full">
            <GetInTouchForm />
          </div>
        </div>
      </div>
    </div>
  );
};

const LabelStylesheet: StyleSheet<'label', CssFactoryPropsType> = createStyleSheet(
  'labelStylesheet',
  {
    label: {
      color: 'white',
    },
  }
);

type TGetInTouchFormValues = {
  firstName: string;
  lastName: string;
  companyName: string;
  phoneNumber: string;
  iAmA: string;
  areYouLookingFor: string[];
  currentClient: string;
};

const FirstNameField = ({
  field: { onChange, onBlur, value, name, ref },
}: {
  field: ControllerRenderProps<TGetInTouchFormValues, 'firstName'>;
}) => <Input inputRef={ref} value={value} name={name} onChange={onChange} onBlur={onBlur} />;

const LastNameField = ({
  field: { onChange, onBlur, value, name, ref },
}: {
  field: ControllerRenderProps<TGetInTouchFormValues, 'lastName'>;
}) => <Input inputRef={ref} value={value} name={name} onChange={onChange} onBlur={onBlur} />;

const CompanyNameField = ({
  field: { onChange, onBlur, value, name, ref },
}: {
  field: ControllerRenderProps<TGetInTouchFormValues, 'companyName'>;
}) => <Input inputRef={ref} value={value} name={name} onChange={onChange} onBlur={onBlur} />;

const PhoneNumberField = ({
  field: { onChange, onBlur, value, name, ref },
}: {
  field: ControllerRenderProps<TGetInTouchFormValues, 'phoneNumber'>;
}) => <Input inputRef={ref} value={value} name={name} onChange={onChange} onBlur={onBlur} />;

const IAmAField = ({
  field: { onChange, name },
}: {
  field: ControllerRenderProps<TGetInTouchFormValues, 'iAmA'>;
}) => {
  return (
    <FormGroup>
      <Radio inline onChange={onChange} value="Asset Manager" name={name}>
        Asset Manager
      </Radio>
      <Radio inline onChange={onChange} value="Family Office" name={name}>
        Family Office
      </Radio>
      <Radio inline onChange={onChange} value="RIA/Wealth Manager" name={name}>
        RIA/Wealth Manager
      </Radio>
      <Radio inline onChange={onChange} value="Hedge Fund" name={name}>
        Hedge Fund
      </Radio>
      <Radio inline onChange={onChange} value="ETF Entrepreneur" name={name}>
        ETF Entrepreneur
      </Radio>
      <Radio inline onChange={onChange} value="Other" name={name}>
        Other
      </Radio>
      <FormFeedback>Please select an option</FormFeedback>
    </FormGroup>
  );
};

const AreYouACurrentClientField = ({
  field: { onChange, name },
}: {
  field: ControllerRenderProps<TGetInTouchFormValues, 'currentClient'>;
}) => {
  return (
    <FormGroup>
      <Radio inline onChange={onChange} value="Yes" name={name}>
        Yes
      </Radio>
      <Radio inline onChange={onChange} value="No" name={name}>
        No
      </Radio>
    </FormGroup>
  );
};

const AreYouLookingForField = ({
  field: { onChange, name, value },
}: {
  field: ControllerRenderProps<TGetInTouchFormValues, 'areYouLookingFor'>;
}) => {
  const handleOnChange = useCallback(
    (event?: CheckboxChangeEvent) => {
      if (!event?.value || !value) {
        return;
      }

      const idx = value.findIndex((elt) => elt === event.value);
      idx < 0 ? value.push(event.value) : value.splice(idx, 1);

      onChange?.(value);
    },
    [onChange, value]
  );
  return (
    <FormGroup>
      <Checkbox inline name={name} value="New Etf Launch" onChange={handleOnChange}>
        New ETF Launch
      </Checkbox>
      <Checkbox inline name={name} value="SMA Conversion" onChange={handleOnChange}>
        SMA Conversion
      </Checkbox>
      <Checkbox inline name={name} value="Mutual Fund Conversion" onChange={handleOnChange}>
        Mutual Fund Conversion
      </Checkbox>
      <Checkbox inline name={name} value="Other" onChange={handleOnChange}>
        Other
      </Checkbox>
    </FormGroup>
  );
};

const GetInTouchForm = () => {
  const formHook = useForm<TGetInTouchFormValues>({
    mode: 'onBlur',
    defaultValues: {
      firstName: '',
      lastName: '',
      companyName: '',
      phoneNumber: '',
      areYouLookingFor: [],
      currentClient: '',
      iAmA: '',
    },
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = formHook;

  const labelStyles = useStyleSheet(LabelStylesheet, null);

  const onHookSubmit = (data: TGetInTouchFormValues) => {
    const formattedEmailBody = `
      First Name: ${data.firstName} %0D%0A
      Last Name: ${data.lastName} %0D%0A
      Company Name: ${data.companyName} %0D%0A
      Phone Number: ${data.phoneNumber} %0D%0A
      Who are you?: ${data.iAmA} %0D%0A
      Are you looking for...?: ${data.areYouLookingFor} %0D%0A
      Are you a current client of Goldman Sachs?: ${data.currentClient} %0D%0A
      `;
    window.location.href = `mailto:GSETFAccelerator@gs.com?subject=GS%20ETF%20Accelerator%20Inquiry&body=${formattedEmailBody}`;
  };

  return (
    <ThemeProvider theme={shadowDarkTheme}>
      <FormProvider {...formHook}>
        <Form onSubmit={handleSubmit(onHookSubmit)} data-testid="get-in-touch-form">
          <div className="flex flex-col xl:flex-row xl:gap-4">
            <FormGroup
              status={errors.firstName ? 'error' : 'none'}
              className="flex flex-col w-full"
            >
              <Label classes={{ label: labelStyles.label }}>
                First Name <span className=" text-red-060">*</span>
              </Label>
              <Controller
                name="firstName"
                control={control}
                rules={{ required: true }}
                render={FirstNameField}
              />
            </FormGroup>

            <FormGroup status={errors.lastName ? 'error' : 'none'} className="flex flex-col w-full">
              <Label classes={{ label: labelStyles.label }}>
                Last Name <span className=" text-red-060">*</span>
              </Label>
              <Controller
                name="lastName"
                control={control}
                rules={{ required: true }}
                render={LastNameField}
              />
            </FormGroup>
          </div>
          <div className="flex flex-col xl:flex-row xl:gap-4">
            <FormGroup
              status={errors.companyName ? 'error' : 'none'}
              className="flex flex-col w-full"
            >
              <Label classes={{ label: labelStyles.label }}>
                Company Name <span className=" text-red-060">*</span>
              </Label>
              <Controller
                name="companyName"
                control={control}
                rules={{
                  required: true,
                }}
                render={CompanyNameField}
              />
            </FormGroup>

            <FormGroup
              status={errors.phoneNumber ? 'error' : 'none'}
              className="flex flex-col w-full"
            >
              <Label classes={{ label: labelStyles.label }}>
                Phone Number <span className=" text-red-060">*</span>
              </Label>
              <Controller
                name="phoneNumber"
                control={control}
                rules={{
                  required: true,
                  pattern: /^[0-9 \-+()]+$/,
                }}
                render={PhoneNumberField}
              />
              <FormFeedback>Phone number must only include characters 0-9, -,+,(,)</FormFeedback>
            </FormGroup>
          </div>

          <FormGroup className="flex flex-col w-full" status={errors.iAmA ? 'error' : 'none'}>
            <Label classes={{ label: labelStyles.label }}>
              Please select which best describes you... <span className=" text-red-060">*</span>
            </Label>
            <Controller
              name="iAmA"
              control={control}
              rules={{
                required: true,
              }}
              render={IAmAField}
            />
          </FormGroup>

          <div className="flex flex-col w-full">
            <Label classes={{ label: labelStyles.label }}>
              Are you a current client of Goldman Sachs?
            </Label>
            <Controller name="currentClient" control={control} render={AreYouACurrentClientField} />
          </div>

          <div className="flex flex-col w-full">
            <Label classes={{ label: labelStyles.label }}>Are you looking for...?</Label>
            <Controller name="areYouLookingFor" control={control} render={AreYouLookingForField} />
          </div>

          <Button type="submit" actionType="primary" classes={{ button: labelStyles.label }}>
            Send via email
          </Button>
        </Form>
      </FormProvider>
    </ThemeProvider>
  );
};
